<template>
    <div>
        <!-- 상단 -->
        <DefaultHeader />

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 블라인드 글 -->
            <v-sheet v-if="status=='블라인드됨'" class="grey lighten-3 py-4 my-4">
                <p class="text-center my-2 font-weight-bold">현재 게시글은 블라인드 된 글입니다.</p>
            </v-sheet>

            <!-- 상단 -->
            <v-sheet v-if="!$vuetify.breakpoint.xs" class="py-3 px-4 d-flex align-center">
                <v-sheet>
                    <p
                        style="width:600px; font-size:18px;"
                        class="ma-0"
                    >
                        <!-- 카테고리 -->
                        <font v-if="category" class="mr-2" style="color:#23D2E2;">{{category}}</font>

                        <!-- 제목 -->
                        <span class="font-weight-bold">
                             {{title}}
                        </span>
                        <br/>

                        <!-- 작성일자 -->
                        <font class="mr-4" style="font-size:16px; color:#B3B3C2;">
                            {{ dayjs(created).format(dayjs().isSame(created, 'day') ? 'a hh:mm' : 'YYYY. MM. DD a hh:mm').replace('am', '오전').replace('pm', '오후') }}
                        </font>

                        <!-- 조회수 -->
                        <font class="mr-4" style="font-size:14px;">
                            조회수 {{view_count}}
                        </font>
                    </p>
                </v-sheet>
                <v-spacer></v-spacer>
                
                <!-- Avatar -->
                <Avatar v-if="writer" :user="writer" />

                <!-- 닉네임 -->
                <p class="ml-2 mr-2 my-0 font-weight-bold" style="font-size:15px;">
                    {{nickname}}
                </p>
            </v-sheet>

            <!-- 상단 (Mobile) -->
            <v-sheet v-if="$vuetify.breakpoint.xs" class="pb-2 px-2 mb-2">
                <v-sheet>
                    <p
                        style="font-size:16px;"
                        class="ma-0 text-truncate"
                    >
                        <!-- 카테고리 -->
                        <font v-if="category" class="mr-2" style="color:#23D2E2;">{{category}}</font>

                        <!-- 제목 -->
                        <span class="font-weight-bold">
                             {{title}}
                        </span>
                    </p>
                </v-sheet>
                <v-sheet class="d-flex align-center">
                    <p style="font-size:11px;" class="mt-4 mb-0">
                        <!-- 작성일자 -->
                        <font class="mr-2" style=" color:#B3B3C2;">
                            {{new Date(created).toLocaleString()}}
                        </font>

                        <!-- 조회수 -->
                        <font class="mr-2" style="">
                            조회수 {{view_count}}
                        </font>
                    </p>

                    <!-- Avatar -->
                    <Avatar v-if="writer" :user="writer" />

                    <!-- 닉네임 -->
                    <p class="ml-2 mr-2 mt-4 mb-0 font-weight-bold" style="font-size:11px;">
                        {{nickname}}
                    </p>
                </v-sheet>
            </v-sheet>


            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>
            <TipTapReader
                v-if="options.content"
                class="tiptap_style"
                :options="options"
            />
            

            <!-- 첨부파일 -->
            <p
                v-if="attachment"
                class="pl-3 mb-0 text-caption"
            >
                <v-icon small class="mb-2px mr-1" color="primary">mdi-attachment</v-icon>
                첨부파일
            </p>
            <v-sheet
                v-if="attachment"
                class="pa-3 mb-2"
            >
                <v-btn
                    depressed
                    class="py-6 rounded-10"
                    @click="download(attachment)"
                >
                    <v-icon size="20" class="mt-1 mr-2" color="primary">mdi-file</v-icon>
                    {{attachment}}
                </v-btn>
            </v-sheet>

            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 좋아요, 싫어요, 댓글 수 -->
            <v-sheet class="mb-2 px-2 d-flex align-center">
                <!-- 좋아요 -->
                <v-btn text class="mr-2 px-1" @click="like()">
                    <v-img class="mr-2" src="@/assets/board/like.png"></v-img>
                    <span style="font-size:12px;">
                        좋아요 {{like_list.length}}
                    </span>
                </v-btn>

                <!-- 싫어요 -->
                <v-btn text class="mr-2 px-1" @click="dislike()">
                    <v-img class="mr-2" src="@/assets/board/dislike.png"></v-img>
                    <span style="font-size:12px;">
                        싫어요 {{dislike_list.length}}
                    </span>
                </v-btn>

                <v-spacer></v-spacer>

                <!-- 좋아요 목록 -->
                <p
                    class="mr-4 my-0"
                    style="font-size:12px; cursor:pointer;"
                    @click="dialog.disliker_list = false; dialog.liker_list = !dialog.liker_list"
                >
                    좋아요 목록
                </p>

                <!-- 싫어요 목록 -->
                <p
                    class="mr-6 my-0"
                    style="font-size:12px; cursor:pointer;"
                    @click="dialog.liker_list = false; dialog.disliker_list = !dialog.disliker_list"
                >
                    싫어요 목록
                </p>

                <!-- 댓글 수 -->
                <p
                    v-if="$route.query.board_name != '자주 묻는 질문 게시판'"
                    class="mr-2 my-0"
                    style="font-size:12px;"
                >
                    {{comment_count}}개의 댓글
                </p>
            </v-sheet>

            <!-- 좋아요 목록 표시 -->
            <div v-if="dialog.liker_list">
                <v-divider class="mx-2"></v-divider>
                <div class="mx-2 px-2 py-2 grey lighten-4 d-flex justify-start align-center">
                    <v-sheet height="16" width="16" color="transparent" class="mr-2">
                        <v-img src="@/assets/board/like.png"></v-img>
                    </v-sheet>
                    <span class="text-caption font-weight-medium">좋아요 목록</span>
                </div>

                <v-divider class="mx-2"></v-divider>
                <v-row
                    class="text-center mt-4 mb-6"
                    no-gutters
                >
                    <v-col
                        v-for="item in like_list" :key="item"
                        cols="3"
                        class="pa-2 text-body-2 font-weight-bold"
                    >
                        {{item}}
                    </v-col>
                </v-row>
            </div>

            <!-- 싫어요 목록 표시 -->
            <div v-if="dialog.disliker_list">
                <v-divider class="mx-2"></v-divider>
                <div class="mx-2 px-2 py-2 grey lighten-4 d-flex justify-start align-center">
                    <v-sheet height="16" width="16" color="transparent" class="mr-2">
                        <v-img src="@/assets/board/dislike.png"></v-img>
                    </v-sheet>
                    <span class="text-caption font-weight-medium">싫어요 목록</span>
                </div>

                <v-divider class="mx-2"></v-divider>
                <v-row
                    class="text-center mt-4 mb-6"
                    no-gutters
                >
                    <v-col
                        v-for="item in dislike_list" :key="item"
                        cols="3"
                        class="pa-2 text-body-2 font-weight-bold"
                    >
                        {{item}}
                    </v-col>
                </v-row>
            </div>

            <!-- 댓글 -->
            <div v-if="$route.query.board_name != '자주 묻는 질문 게시판'">
                <!-- 비로그인 상태 -->
                <v-sheet
                    v-if="!$store.state.is_logined"
                    class="py-6 mx-3 my-4"
                    style="background:#f9f9f9; border:1px solid #eee;"
                >
                    <p class="mb-0 text-body-2 text-center">로그인한 회원만 댓글을 볼 수 있습니다</p>
                </v-sheet>

                <!-- 로그인 상태 -->
                <v-sheet v-else>
                    <Comment
                        :board_name="board_name"
                        :title="title"
                        :writer="writer"
                        @updated="loadComment()"
                    />
                </v-sheet>
            </div>
        </v-sheet>

        <!-- 게시글 관련 버튼 -->
        <div
            class="d-flex"
            :class="$vuetify.breakpoint.xs? 'mt-2':'mt-3'"
        >
            <!-- 목록 -->
            <v-btn
                :x-small="$vuetify.breakpoint.xs"
                dark
                depressed
                :height="$vuetify.breakpoint.xs? 28 : 35"
                :width="$vuetify.breakpoint.xs? null : 80"
                :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                class="font-weight-bold"
                color="#ADAFCA"
                @click="backToList()"
            >
                목록
            </v-btn>

            <!-- 좋아요 추첨 -->
            <v-btn
                :x-small="$vuetify.breakpoint.xs"
                dark
                depressed
                :height="$vuetify.breakpoint.xs? 28 : 35"
                :width="$vuetify.breakpoint.xs? null : 120"
                :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                class="ml-2 font-weight-bold"
                color="primary"
                @click="dialog.likeDraw = true"
            >
                좋아요 추첨
            </v-btn>
            <v-spacer></v-spacer>
            
            <!-- 글쓴이가 아닐 경우 -->
            <div v-if="writer!=$store.state.user_id">
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    dark
                    depressed
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :width="$vuetify.breakpoint.xs? null : 80"
                    :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                    class="ml-2 font-weight-bold"
                    color="#23D2E2"
                    @click="report_post_open()"
                >
                    신고
                </v-btn>
            </div>

            <!-- 관리자일 경우 -->
            <div v-if="$store.state.type=='관리자' && status!='블라인드됨'">
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    dark
                    depressed
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                    class="ml-2 font-weight-bold px-4"
                    color="#23D2E2"
                    @click="blindPost()"
                >
                    블라인드
                </v-btn>
            </div>

            <!-- 관리자일 경우 -->
            <div v-if="$store.state.type=='관리자' && status=='블라인드됨'">
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    dark
                    depressed
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                    class="ml-2 font-weight-bold px-4"
                    color="#23D2E2"
                    @click="blindClearPost()"
                >
                    블라인드 해제
                </v-btn>
            </div>

            <!-- 관리자일 경우 -->
            <div v-if="$store.state.type=='관리자' || $store.state.type=='운영진'">
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    dark
                    depressed
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                    class="ml-2 font-weight-bold px-4"
                    color="#615DFA"
                    @click="dialog.movePost = true"
                >
                    게시판 이동
                </v-btn>
            </div>

            <!-- 글쓴이일 경우 또는 관리자일 경우 -->
            <div
                v-if="writer==$store.state.user_id || $store.state.type=='관리자' || $store.state.type=='운영진'"
                class="d-flex"
            >
                <!-- 수정 -->
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    dark
                    depressed
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :width="$vuetify.breakpoint.xs? null : 80"
                    :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                    class="ml-2 font-weight-bold"
                    color="#FF7A51"
                    @click="updatePost()"
                >
                    수정
                </v-btn>

                <!-- 삭제 -->
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    dark
                    depressed
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :width="$vuetify.breakpoint.xs? null : 80"
                    :class="$vuetify.breakpoint.xs? 'rounded-lg':'rounded-10'"
                    class="ml-2 font-weight-bold"
                    color="#F8468D"
                    @click="deletePost()"
                >
                    삭제
                </v-btn>
            </div>
        </div>

        <div
            class="mt-3 d-flex justify-end"
        >
            <BannerView
                v-if="$store.state.ads_banner.community"
                type="community"
                width="360"
                height="46"
                :round="'rounded-10'"
            />
        </div>

        <!-- 게시판 이동하기 Dialog -->
        <v-dialog v-model="dialog.movePost" width="580" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                <p
                    class="text-center font-weight-bold mb-8"
                    style="font-size:20px;"
                >
                    게시판 이동하기
                </p>

                <!-- 이동 전 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">이동 전</span>
                        <v-sheet class="text-truncate" style="width:200px;">
                            {{$route.query.board_name}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 이동 후 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:98px;">이동 후</span>
                        <v-select
                            style="max-width:260px;"
                            placeholder="이동 후"
                            dense
                            hide-details
                            solo
                            flat
                            :items="board_list"
                            v-model="selected_update_board"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <div class="mt-4 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="#615DFA"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="postMoveSubmit()"
                    >
                        이동
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="white"
                        class="font-weight-bold rounded-10"
                        @click="dialog.movePost = false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 게시글 신고하기 Dialog -->
        <v-dialog v-model="dialog.reportPost" width="800" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                <p
                    class="text-center font-weight-bold mb-8"
                    style="font-size:20px;"
                >
                    게시글 신고하기
                </p>

                <!-- 신고 게시글 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">신고 게시글</span>
                        <v-sheet class="text-truncate" style="width:400px;">
                            {{title}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 신고 대상자 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:110px;">신고 대상자</span>
                        <v-sheet class="text-truncate" style="width:400px;">
                            {{writer}}
                        </v-sheet>
                    </div>
                </v-sheet>

                <!-- 신고 항목 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <div class="d-flex align-center">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold; margin-right:240px;">신고 항목</span>
                        <v-select
                            style="max-width:260px;"
                            placeholder="신고 항목"
                            dense
                            hide-details
                            solo
                            flat
                            :items="select_list.report"
                            v-model="reportPost.category"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </div>
                </v-sheet>

                <!-- 신고 내용 -->
                <v-sheet class="py-4 px-6 mt-3 rounded-10">
                    <v-icon size="8" color="#707070" class="pb-2px mr-3">mdi-circle</v-icon>
                    <span style="font-size:15px; font-weight:bold;">신고 내용</span>
                    <v-textarea
                        class="no_under"
                        rows="4"
                        hide-details
                        v-model="reportPost.content"
                        placeholder="내용을 입력해주세요"
                    ></v-textarea>
                </v-sheet>

                <div class="mt-4 pa-2 d-flex justify-center">
                    <!-- 제출 -->
                    <v-btn
                        width="110"
                        height="42"
                        dark
                        depressed
                        color="#615DFA"
                        class="font-weight-bold rounded-10 mr-2"
                        @click="report_post_submit()"
                    >
                        신고
                    </v-btn>

                    <!-- 취소 -->
                    <v-btn
                        width="110"
                        height="42"
                        depressed
                        color="white"
                        class="font-weight-bold rounded-10"
                        @click="dialog.reportPost=false"
                    >
                        취소
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 좋아요 추첨 Dialog -->
        <v-dialog v-model="dialog.likeDraw" width="600" content-class="rounded-15">
            <v-sheet class="py-8 px-12" color="#F8F7FB">
                
                <div
                    v-if="like_draw_list.length == 0"
                >
                    <!-- 글쓴이 -->
                    <div
                        v-if="writer == $store.state.user_id"
                    >
                        <p
                            class="text-center font-weight-bold mb-8"
                            style="font-size:20px;"
                        >
                            좋아요 포인트 추첨
                        </p>

                        <p
                            class="font-weight-bold ml-2 mb-2"
                            style="font-size:13px;"
                        >
                            {{title}}
                        </p>

                        <!-- 지급 설정 -->
                        <v-sheet class="py-4 px-6 mt-3 pb-8 rounded-10">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span style="font-size:15px; font-weight:bold;">지급 설정</span>
                            
                            <v-text-field
                                height="48"
                                style=" font-size:18px;"
                                class="rounded-15 font-weight-bold mt-6 mx-2"
                                label="총 몇명에게"
                                dense
                                outlined
                                hide-details
                                suffix="명"
                                v-model="draw_user_amount"
                            ></v-text-field>

                            <v-text-field
                                height="48"
                                style=" font-size:18px;"
                                class="rounded-15 font-weight-bold mt-4 mx-2"
                                label="몇 포인트씩 줄지"
                                dense
                                outlined
                                hide-details
                                suffix="P"
                                v-model="draw_point_amount"
                            ></v-text-field>
                        </v-sheet>

                        <div class="mt-4 pa-2 d-flex justify-center">
                            <!-- 추첨 -->
                            <v-btn
                                width="110"
                                height="42"
                                dark
                                depressed
                                color="#615DFA"
                                class="font-weight-bold rounded-10 mr-2"
                                @click="likeDrawSubmit()"
                            >
                                추첨
                            </v-btn>

                            <!-- 닫기 -->
                            <v-btn
                                width="110"
                                height="42"
                                depressed
                                color="white"
                                class="font-weight-bold rounded-10"
                                @click="dialog.likeDraw = false"
                            >
                                닫기
                            </v-btn>
                        </div>
                    </div>

                    <div v-else>
                        <!-- 글쓴이 외 -->
                        <p
                            class="text-center font-weight-bold mb-8"
                            style="font-size:20px;"
                        >
                            좋아요 포인트 추첨 결과
                        </p>

                        <p
                            class="font-weight-bold ml-2 mb-2"
                            style="font-size:13px;"
                        >
                            {{title}}
                        </p>
                        <v-sheet
                            class="d-flex justify-center align-center py-6 rounded-10"
                        >
                            <p
                                class="my-16 text-body-2 font-weight-medium"
                            >
                                좋아요 추첨을 하지 않은 게시물입니다
                            </p>
                        </v-sheet>
                    </div>
                </div>


                <div
                    v-if="like_draw_list.length != 0"
                >
                    <p
                        class="text-center font-weight-bold mb-8"
                        style="font-size:20px;"
                    >
                        좋아요 포인트 추첨 결과
                    </p>

                    <p
                        class="font-weight-bold ml-2 mb-2"
                        style="font-size:13px;"
                    >
                        {{title}}
                    </p>
                    <p
                        class="font-weight-bold ml-2 mb-2"
                        style="font-size:11px;"
                    >
                        총 {{like_draw_list.filter(e => e.point !=0).length}}명에게 {{$toComma(like_draw_list.filter(e => e.point !=0)[0].point)}}P 씩 지급
                        <span class="ml-3" style="color:#ADAFCA; font-weight:400;">{{new Date(created).toLocaleString()}}</span>
                    </p>

                    <!-- 결과 -->
                    <v-sheet class="py-4 px-6 mt-3 pb-8 rounded-10">
                        <p
                            class="mb-3"
                        >
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span style="font-size:15px; font-weight:bold;">결과</span>
                        </p>
                        
                        <v-sheet
                            v-for="item in like_draw_list" :key="item.user_id"
                            class="d-flex justify-space-between align-center mx-3 my-1"
                        >
                            <div
                                class="d-flex"
                            >
                                <ProfileMini
                                    :user="item.user_id"
                                />
                                <span class="ml-2">{{item.nickname}}</span>
                            </div>

                            <v-spacer></v-spacer>

                            <p class="font-weight-medium text-caption mb-0" style="color:#ADAFCA;">{{new Date(item.created).toLocaleString()}}</p>

                            <p class="ml-10 font-weight-medium mb-0 text-center" style="width:60px;" :style="item.is_win? 'color:#615DFA;':'color:#ADAFCA;'">
                                {{item.is_win? "당첨":"꽝"}}
                            </p>
                        </v-sheet>
                    </v-sheet>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import DefaultHeader from "@/views/board/components/DefaultHeader"
import Avatar from "@/components/Avatar"
import Profile from "@/components/Profile"
import TipTapReader from "@/components/tiptap/Reader"
import Comment from "./Pc/comment"
import GradeCheck from '@/components/GradeCheck.js'
import PointModule from '@/components/PointModule.js'

import ProfileMini from '@/components/ProfileForBoardMobile'
import BannerView from '@/components/BannerView'

export default {
    components: {
        DefaultHeader,
        Avatar,
        Profile,
        TipTapReader,
        Comment,
        ProfileMini,
        BannerView
    },

    data: () => ({
        category: "",
        title: "",
        created: "",
        writer: "",
        profile_image: "",
        nickname: "",
        view_count: 0,
        board_name: "",

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        comment_count: 0,

        like_list: [],
        dislike_list: [],

        board_list: [],
        selected_update_board: "",

        status: "",

        dialog: {
            reportPost: false,
            movePost: false,
            liker_list: false,
            disliker_list: false,
            likeDraw: false
        },

        select_list: {
            report: [
                "욕설/비방",
                "도배",
                "홍보/사업성",
                "음란성",
                "분란조장",
                "시세조작",
                "기타"
            ]
        },

        reportPost: {
            category: "",
            content: ""
        },

        attachment: "",

        draw_user_amount: 0,
        draw_point_amount: 0,
        like_list_user: [],
        dislike_list_user: [],
        like_draw_list: []
    }),

    mounted(){
        // 게시글 정보 가져오기
        this.load()

        // 댓글 가져오기 (for 댓글 수)
        this.loadComment()

        // 추천한 사람 리스트 가져오기
        this.loadPostLikeList()

        // 비추천한 사람 리스트 가져오기
        this.loadPostDislikeList()

        // 좋아요 추첨 결과 가져오기
        this.loadLikeDrawList()

        // 게시판 이동하기 > 게시판 목록
        this.$http.post('/api/board/read/board_list').then((res) => {
            this.board_list = res.data
            this.board_list = this.board_list.map(e => e.board_name)
        })
    },

    methods: {
        // 좋아요 추첨 > 배열 랜덤하게 섞기
        shuffleArray(array) {
            return array.sort(() => Math.random() - 0.5)
        },

        // 좋아요 추첨 > 랜덤으로 요소 뽑기
        getRandomElements(arr, m) {
            // 원본 배열 복사
            let shuffledArray = this.shuffleArray(arr.slice())
            return shuffledArray.slice(0, m)
        },

        // 좋아요 추첨
        async likeDrawSubmit(){
            // 좋아요 3이상인지 확인
            if(this.like_list_user.length <= 2)
            {
                alert("좋아요를 3개 이상 받은 게시물만 가능합니다.")
                return
            }

            // 좋아요 수보다 많이 선택했는지 확인
            if(this.like_list_user.length < this.draw_user_amount)
            {
                alert("좋아요 유저 수보다 선택한 명수가 더 많습니다.")
                return
            }

            // 지급할 포인트가 있는지 확인
            let response = await this.$http.post("/api/user/select/specific", {
                params: {
                    user_id: this.$store.state.user_id
                }
            })
            if (response.data[0].point < this.draw_user_amount * this.draw_point_amount) {
                alert(this.draw_user_amount + "명에게 " + this.$toComma(this.draw_point_amount) + "지급하기에 보유한 포인트가 모자랍니다.")
                return
            }

            // 알림 보내기
            this.like_list_user.forEach(user_id => {
                this.$http.post('/api/alarm/insert', {
                    params: {
                        user_id: user_id,
                        sender: this.$store.state.user_id,
                        type: "like_draw",
                        content: this.title,
                        link: this.$route.fullPath
                    }
                })
            })

            // 당첨자
            let winner_list = this.getRandomElements(this.like_list_user, this.draw_user_amount)
            console.log(winner_list)

            winner_list.forEach(user_id => {
                this.$http.post("/api/like_draw/insert", {
                    params: {
                        post_id: this.$route.query.id,
                        user_id: user_id,
                        point: this.draw_point_amount,
                        is_win: 1
                    }
                }).then((res) => {
                    console.log(res)

                    PointModule.Update(this.$store.state.user_id, -this.draw_point_amount)
                    PointModule.Record(this.$store.state.user_id, '게시판', '포인트 추첨', this.title, -this.draw_point_amount)

                    PointModule.Update(user_id, this.draw_point_amount)
                    PointModule.Record(user_id, '게시판', '포인트 추첨', this.title, this.draw_point_amount)
                })
            })

            // 꽝
            let loser_list = this.like_list_user.filter(item => !winner_list.includes(item))
            console.log(loser_list)

            loser_list.forEach(user_id => {
                this.$http.post("/api/like_draw/insert", {
                    params: {
                        post_id: this.$route.query.id,
                        user_id: user_id,
                        point: 0,
                        is_win: 0
                    }
                }).then((res) => {
                    console.log(res)
                })
            })

            // 좋아요 추첨 결과 가져오기
            this.loadLikeDrawList()
        },

        // 좋아요 추첨 결과 가져오기
        loadLikeDrawList(){
            this.$http.post("/api/like_draw/select", {
                params: {
                    post_id: this.$route.query.id
                }
            }).then((res) => {
                if(res.data.length){
                    this.like_draw_list = res.data
                }
            })
        },

        // 게시글 정보 가져오기
        load(){
            this.$http.post('/api/board/read', {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                this.category = res.data[0].category
                this.title = res.data[0].title
                this.options.content = res.data[0].content
                this.created = res.data[0].created
                this.writer = res.data[0].writer
                this.profile_image = res.data[0].profile_image
                this.nickname = res.data[0].nickname
                this.view_count = res.data[0].view_count
                this.board_name = res.data[0].board_name
                this.status = res.data[0].status
                this.attachment = res.data[0].attachment

                if(this.status=='블라인드됨' && this.$store.state.type!='관리자'){
                    alert("잘못된 접근입니다.")
                    this.$router.go(-1)
                }
            })
        },

        // 댓글 가져오기 (for 댓글 수)
        loadComment(){
            this.$http.post('/api/board/read/comment/count', {
                params: {
                    post_id:this.$route.query.id
                }
            }).then((res) => {
                this.comment_count = res.data[0].total_count
            })
        },

        // 추천한 사람 리스트 가져오기
        loadPostLikeList(){
            this.$http.post('/api/board/read/like/list', {
                params: {
                    post_id: this.$route.query.id
                }
            }).then((res) => {
                this.like_list = res.data.map(a => a.nickname)
                this.like_list_user = res.data.map(a => a.user_id)
            })
        },

        // 비추천한 사람 리스트 가져오기
        loadPostDislikeList(){
            this.$http.post('/api/board/read/dislike/list', {
                params: {
                    post_id: this.$route.query.id
                }
            }).then((res) => {
                this.dislike_list = res.data.map(a => a.nickname)
                this.dislike_list_user = res.data.map(a => a.user_id)
            })
        },

        // 추천
        like(){
            if(!this.$store.state.is_logined){
                alert("게시글 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 게시글 추천이 제한됩니다.')
            }else if(this.$store.state.user_id==this.writer){
                alert('자신의 글에 추천을 할 수 없습니다.')
            }else{
                if(this.like_list.includes(this.$store.state.nickname)){
                    alert("이미 추천한 게시글입니다.")
                }else{
                    this.$http.post('/api/board/read/like', {
                        params: {
                            post_id: this.$route.query.id,
                            user_id: this.$store.state.user_id
                        }
                    }).then((res) => {
                        if(res.status=='200'){
                            // 포인트 적립
                            if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                PointModule.amountCheck('카드자랑', '추천/비추천').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '카드자랑', '추천/비추천', this.title, res.data[0].amount)
                                })
                            }else if(this.$route.query.board_group=='공략/배틀'){
                                PointModule.amountCheck('공략게시판', '추천/비추천').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '공략게시판', '추천/비추천', this.title, res.data[0].amount)
                                })
                            }else{
                                PointModule.amountCheck('일반게시판', '추천/비추천').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '일반게시판', '추천/비추천', this.title, res.data[0].amount)
                                })
                            }

                            // 게시글 작성자에게 좋아요 알림
                            this.$http.post('/api/alarm/insert', {
                                params: {
                                    user_id: this.writer,
                                    sender: this.$store.state.user_id,
                                    type: "post_liked",
                                    link: this.$route.fullPath
                                }
                            }).then(res => {
                                console.log(res)
                            })

                            // 추천한 사람 리스트 가져오기
                            this.loadPostLikeList()
                        }
                    })
                }
            }   
        },

        // 비추천
        dislike(){
            if(!this.$store.state.is_logined){
                alert("게시글 비추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 게시글 비추천이 제한됩니다.')
            }else if(this.$store.state.user_id==this.writer){
                alert('자신의 글에 비추천을 할 수 없습니다.')
            }else{
                if(this.dislike_list.includes(this.$store.state.nickname)){
                    alert("이미 비추천한 게시글입니다.")
                }else{
                    this.$http.post('/api/board/read/dislike', {
                        params: {
                            post_id: this.$route.query.id,
                            user_id: this.$store.state.user_id
                        }
                    }).then((res) => {
                        if(res.status=='200'){
                            // 포인트 적립
                            if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                                PointModule.amountCheck('카드자랑', '추천/비추천').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '카드자랑', '추천/비추천', this.title, res.data[0].amount)
                                })
                            }else if(this.$route.query.board_group=='공략/배틀'){
                                PointModule.amountCheck('공략게시판', '추천/비추천').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '공략게시판', '추천/비추천', this.title, res.data[0].amount)
                                })
                            }else{
                                PointModule.amountCheck('일반게시판', '추천/비추천').then((res) => {
                                    PointModule.Update(this.$store.state.user_id, res.data[0].amount)
                                    PointModule.Record(this.$store.state.user_id, '일반게시판', '추천/비추천', this.title, res.data[0].amount)
                                })
                            }

                            // 게시글 작성자에게 싫어요 알림
                            this.$http.post('/api/alarm/insert', {
                                params: {
                                    user_id: this.writer,
                                    sender: this.$store.state.user_id,
                                    type: "post_disliked",
                                    link: this.$route.fullPath
                                }
                            }).then(res => {
                                console.log(res)
                            })

                            // 비추천한 사람 리스트 가져오기
                            this.loadPostDislikeList()
                        }
                    })
                }
            }   
        },

        // 목록
        backToList(){
            this.$router.push("/board/list?board_group="+this.$route.query.board_group+"&board_name="+this.board_name)
            // this.$router.go(-1)
        },

        // 수정하기
        updatePost(){
            this.$router.push("/board/update?board_group="+this.$route.query.board_group+"&board_name="+this.board_name+"&id="+this.$route.query.id)
        },

        // 삭제하기
        deletePost(){
            if(confirm("정말 해당 글을 삭제하시겠습니까?")){
                GradeCheck.compare(this.writer, 'post', -1)
                .then(() => {
                    this.$http.post('/api/board/read/delete', {
                        params: {
                            id : this.$route.query.id
                        }
                    }).then(() => {
                        // 포인트 적립 취소
                        if(this.$route.query.board_group=='커뮤니티' && this.board_name=='인증/카드자랑'){
                            PointModule.amountCheck('카드자랑', '글쓰기').then((res) => {
                                PointModule.Update(this.writer, -res.data[0].amount)
                                PointModule.Record(this.writer, '카드자랑', '글쓰기', this.title, -res.data[0].amount)
                            })
                        }else if(this.$route.query.board_group=='공략/배틀'){
                            PointModule.amountCheck('공략게시판', '글쓰기').then((res) => {
                                PointModule.Update(this.writer, -res.data[0].amount)
                                PointModule.Record(this.writer, '공략게시판', '글쓰기', this.title, -res.data[0].amount)
                            })
                        }else{
                            PointModule.amountCheck('일반게시판', '글쓰기').then((res) => {
                                PointModule.Update(this.writer, -res.data[0].amount)
                                PointModule.Record(this.writer, '일반게시판', '글쓰기', this.title, -res.data[0].amount)
                            })
                        }

                        this.$router.push("/board/list?board_group="+this.$route.query.board_group+"&board_name="+this.board_name)
                    })
                })
            }
        },

        // 게시판 이동하기
        postMoveSubmit(){
            if(this.selected_update_board==''){
                alert('이동할 게시판을 선택하지 않았습니다.')
            }else{
                this.$http.post('/api/board/read/update/board_name', {
                    params: {
                        board_name :this.selected_update_board,
                        id :this.$route.query.id
                    }
                }).then(() => {
                    // 글작성자에게 게시글 이동 알림
                    this.$http.post('/api/alarm/insert', {
                        params: {
                            user_id: this.writer,
                            sender: this.$store.state.user_id,
                            type: "postMove",
                            content: "작성하신 게시글이 "+this.board_name+"에서 "+this.selected_update_board+"으로 글이 이동되었습니다.",
                            link: "/board/read?board_group="+this.$route.query.board_group+"&board_name="+this.selected_update_board+"&id="+this.$route.query.id
                        }
                    })

                    // 해당 게시글에 댓글 추가
                    this.$http.post('/api/board/read/comment/insert', {
                        params: {
                            post_id: this.$route.query.id,
                            user_id: this.$store.state.user_id,
                            content: "관리자가 " + this.selected_update_board + "으로 게시글을 이동했습니다."
                        }
                    })

                    alert('해당 게시판으로 글이 이동되었습니다.')
                    this.$router.push("/board/read?board_group="+this.$route.query.board_group+"&board_name="+this.selected_update_board+"&id="+this.$route.query.id)
                })
            }
        },

        // 게시글 블라인드 처리
        blindPost(){
            if(confirm("정말 해당 게시글을 블라인드 처리하시겠습니까?")){
                this.$http.post('/api/board/read/blind', {
                    params: {
                        id : this.$route.query.id
                    }
                }).then(() => {
                    alert('해당 게시글이 블라인드 처리되었습니다')
                    this.$router.go(this.$router.currentRoute)
                })
            }
        },

        // 게시글 블라인드 해제
        blindClearPost(){
            if(confirm("정말 해당 게시글을 블라인드 해제하시겠습니까?")){
                this.$http.post('/api/board/read/blind/clear', {
                    params: {
                        id : this.$route.query.id
                    }
                }).then(() => {
                    alert('해당 게시글이 블라인드 해제되었습니다')
                    this.$router.go(this.$router.currentRoute)
                })
            }
        },

        // 게시글 신고하기
        report_post_open(){
            if(!this.$store.state.is_logined){
                alert("신고하기는 로그인 후 이용가능합니다")
            }else {
                this.dialog.reportPost = true
            }
        },

        // 게시글 신고 제출
        report_post_submit(){
            if(this.reportPost.category==""){
                alert("신고 항목을 선택해주세요")
            }else if(this.reportPost.content==""){
                alert("신고 내용을 입력해주세요")
            }else{
                this.$http.post('/api/admin/user/reported/insert', {
                    params: {
                        reporter_user_id: this.$store.state.user_id,
                        reported_user_id: this.writer,
                        type: "board_post",
                        reported_id: this.$route.query.id,
                        category: this.reportPost.category,
                        content: this.reportPost.content,
                        link: this.$route.fullPath
                    }
                }).then((res) => {
                    console.log(res)
                    if(res.data.affectedRows){
                        // DB 알림
                        this.$dbAlarm(
                            "신고 - 게시글",
                            this.nickname,
                            this.user_id
                        )

                        alert("신고가 접수되었습니다.")
                        this.dialog.reportPost = false
                    }
                })
            }
        },

        download(file) {
            this.$http.post("/api/image/download", {
                params: {
                    file: file
                }
            },
            {
                responseType: 'blob' // 응답 타입을 'blob'으로 설정
            }).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data])) // 블롭 객체 생성
                const link = document.createElement('a') // 링크 엘리먼트 생성
                link.href = url // 링크에 다운로드 URL 설정
                link.setAttribute('download', file) // 파일 이름 설정
                document.body.appendChild(link) // 링크를 body에 추가
                link.click() // 클릭 이벤트 발생
                document.body.removeChild(link) // 링크 제거
                window.URL.revokeObjectURL(url) // 사용한 URL 해제
            })
        },
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

.v-input--is-focused >>> fieldset {
  border-color: #23D2E2;
}

.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:15px;
    font-weight:400;
}
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    font-weight:bold;
    margin-left:8px;
    font-size: 18px;
}

/* 페이지네이션 */
::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#23D2E2 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}

.v-chip {
    height:36px !important;
    border:2px solid #f0f0f0 !important;
    background: white !important;
}

.v-chip--active {
    height:36px !important;
    border:2px solid #23D2E2 !important;
    background: white !important;
}
</style>
<style scoped>
::v-deep .v15 input {
    font-weight:bold;
    margin-left:8px;
    font-size: 15px !important;
}

::v-deep .v15 .v-select {
    font-weight:bold !important;
    margin-left:8px;
    font-size: 15px !important;
}
</style>
<style>
/* V-select 색상변경 */
.v-select.v-input--dense .v-select__selection--comma {
    color: #434361;
}

.custom-button::v-deep {
  width: 33px !important;
}
</style>

<style scoped>
/* mobile */
::v-deep .mobile .v-text-field .v-input__control .v-input__slot {
    min-height: 0 !important;
    padding: 0 8px !important;
    margin-bottom: 2px !important;
    display: flex !important;
    align-items: center !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 5px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label {
    margin-top: -12px !important;
}

::v-deep .mobile .v-text-field .v-input__control .v-input__slot label.v-label--active {
    margin: 0 0 0 5px !important;
}

::v-deep .mobile .v-text-field__details {
    margin: 2px !important;
}

::v-deep .mobile .v-text-field input {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

::v-deep .mobile .v-select__slot {
    font-weight:bold !important;
    margin-left:8px !important;
    font-size: 12px !important;
}

/* 입력칸 */
.mobile .v-text-field--outlined >>> fieldset {
    background:white;
}

.customHover:hover {
    border:1px solid #34d6e4 !important;
}

</style>